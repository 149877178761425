<template>
  <div class="DashBoard">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <Topo
        titulo="Cartão do segurado"
        @OpenMenuLateral="menuOpen = true"
      ></Topo>
      <div
        v-if="dadosUser.dataAtivacao || dadosUser.codigoAssociadoSGA"
        class="cartao"
        :style="returnaStyle()"
      >
        <div class="inside">
          <b-row>
            <b-col cols="8">
              <h5>Cartão do segurado</h5>
              <div class="assistencia">Assistencia 24 horas</div>
              <div class="telefone-assistencia">
                <a :href="`tel:${dados.telefoneAssistencia}`">{{
                  dados.telefoneAssistencia
                }}</a>
              </div>
              <div class="central">Central de Atendimento</div>
              <div class="telefone-central">
                <a :href="`tel:${dados.telefone}`">{{ dados.telefone }}</a>
              </div>
            </b-col>
            <b-col>
              <img :src="dados.logo" style="width: 100%" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h4 v-if="dadosUser.dataAtivacao" class="numero-cartao">
                {{ formataAno(dadosUser.dataAtivacao) }}
                {{ formataRegional(dadosUser.idRegional) }}
                {{ formataId(dadosUser.idProspect) }}
              </h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <div class="nome-small">Nome</div>
              <div class="nome">{{ dadosUser.nome }}</div>
            </b-col>
            <b-col>
              <div class="desde-small">Cliente desde</div>
              <div v-if="dadosUser.createdAt" class="desde">
                {{ formataDesde(dadosUser.dataAtivacao) }}
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './TopoInterno';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import service from '../services';
import pdf from 'vue-pdf';
import moment from 'moment';
import { decryptData } from '../services/decryptData';

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      dados: {},
      dadosUser: {},
      carregando: false,
      regulamento: null,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: { Topo, ConteudoDash, MenuRodape, MenuLateral, pdf },
  methods: {
    formataRegional(id) {
      if (id) return id.toString().padStart(4, '0');
      else return '0000';
    },
    formataId(id) {
      if (id) return id.toString().padStart(6, '0');
      else return '000000';
    },
    formataDesde(data) {
      if (data) return moment(data).format('MM/YYYY');
    },
    formataAno(data) {
      if (data) return moment(data).format('YYYY');
    },
    returnaStyle() {
      var obj = {};
      obj.width = `${this.windowWidth}px`;
      obj.height = `${this.windowHeight - 120}px`;
      if (this.windowWidth > this.windowHeight)
        obj.height = `${this.windowHeight + 300}px`;
      return obj;
    },
    verificaToken() {
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
        console.log(this.decoded);

        service
          .getAll('g', {}, 'empresas/list/idEmpresa/' + this.decoded.id_empresa)
          .then((res) => {
            res.data = decryptData(res.data);
            this.dados = res.data[0];
          })
          .catch((e) => {
            this.carregando = false;
            this.erroConexao =
              'Seu aplicativo parece estar offline, verifique sua conexao';
          });

        service
          .getAll(
            'g',
            {},
            'associados/list/idProspect/' + this.decoded.id_prospect,
          )
          .then((res) => {
            res.data = decryptData(res.data);
            console.log(res);
            this.dadosUser = res.data[0];
            console.log(this.dadosUser);
            if (
              !this.dadosUser.dataAtivacao &&
              !this.dadosUser.codigoAssociadoSGA
            ) {
              this.$bvToast.toast(
                'Você ainda não é um usuário ativo, entre em contato com a central de Atendimento ' +
                  this.dados.telefone,
                {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 2500,
                },
              );
              setTimeout(() => {
                this.$router.push('/dashboard');
              }, 3000);
            }
          })
          .catch((e) => {
            this.carregando = false;
            this.erroConexao =
              'Seu aplicativo parece estar offline, verifique sua conexao';
          });
      }
    },
  },
  mounted() {
    this.verificaToken();
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  overflow-x: hidden;
}

.iconeFechar {
  font-size: 28px;
}
.bm-menu {
  background: #373a47;
}
.bm-menu {
  background-color: #3f3f41;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: 0.2s;
  width: 0;
  z-index: 9999;
  color: #fff;
}
.bm-item-list > * {
  display: flex;
  padding: 0.7em;
  text-decoration: none;
}
.bm-item-list > * > span {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
.bm-item-list > * > i {
  color: #666;
  font-weight: 700;
  margin-left: 10px;
}
.logoBottom {
  margin-top: 100px;
  text-align: center;
  opacity: 0.5;
}

.cartao {
  font-family: 'Play', sans-serif;
  position: relative;
  margin-bottom: 0px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url('https://bboneapp.s3.amazonaws.com/mobile/bg-cartao.png');
}

.cartao .inside {
  padding: 5%;
}

.cartao .inside .assistencia,
.cartao .inside .central {
  font-size: 10px;
}

.cartao .inside .telefone-assistencia,
.cartao .inside .telefone-central {
  font-size: 12px;
}

.cartao .inside .telefone-assistencia a,
.cartao .inside .telefone-central a {
  color: #373a47;
}

.numero-cartao {
  margin-top: 10px;
  text-align: center;
}

.nome-small,
.desde-small {
  font-size: 10px;
}

.nome,
.desde {
  font-size: 13px;
}

@media only screen and (orientation: landscape) {
  .cartao .inside h5 {
    font-size: 30px;
  }

  .cartao .inside .assistencia,
  .cartao .inside .central {
    font-size: 14px;
    margin-top: 10px;
  }

  .cartao .inside .telefone-assistencia,
  .cartao .inside .telefone-central {
    font-size: 18px;
  }

  .numero-cartao {
    margin-top: 20px;
    text-align: center;
    font-size: 38px;
  }

  .nome-small,
  .desde-small {
    font-size: 14px;
    margin-top: 20px;
  }

  .nome,
  .desde {
    font-size: 18px;
  }
}
</style>
